import { throttle } from "./helpers/throttle.js";

// General note: the dataLayer will always be populated regardless of didomi-consent status, GTM will only store data when consent is given
export function initTracking() {
  if (!window.gnabb?.trackingData?.content) {
    return;
  }
  window.gnabb.trackingData.content.location = document.location.href;
  window.didomiOnReady.push(() => {
    trackPageView();
    initScrollTracking();
    initScrollDepthTracking();
    initLinkClickTracking();
  });
}
/**
 * Creates a selector for grabbing elements only matching a certain
 * tracking type in their space-separated data-track attribute.
 *
 * @param {string} type
 * @returns {string}
 */
function trackingTypeSelector(type) {
  return `[data-track~="${type}"]`;
}

function trackPageView() {
  const trackingData = window.gnabb.trackingData;
  if (trackingData?.content) {
    trackingData.content.title = document.title;
    trackingData.content.referrer = document.referrer;
  }

  const pageView = {
    ...trackingData,
    event: "page view",
  };
  window.dataLayer.push(pageView);
}

function initScrollTracking() {
  const elementsToTrack = document.querySelectorAll(trackingTypeSelector("view"));
  const observer = new IntersectionObserver(
    (entries) => {
      const [ entry ] = entries;
      if (entry.isIntersecting) {
        trackVirtualPageView({
          content: {
            articleIndex: Number(entry.target.dataset.itemIndex) + 1, // Yes, in Analytics universe index starts at 1
            location: `${document.location.origin}${entry.target.dataset.referenceUrl}`,
            referrer: document.referrer,
            type: "article",
            id: entry.target.dataset.contentId ? { value: entry.target.dataset.contentId, system: "ia" } : undefined,
            pageNotFound: false,
            userHasAccess: window.gnabb.trackingData.content.userHasAccess,
            trigger: "virtual page load",
          },
        });
        observer.unobserve(entry.target);
      }
    },
    // Look only at the top 3% of the root (which is the window)
    { rootMargin: "0% 0% -97% 0%" }
  );
  elementsToTrack.forEach((el) => {
    observer.observe(el);
  });
}

function initScrollDepthTracking() {
  if (!document.body.dataset.trackScrollDepth) return;

  let milestones = [ 25, 50, 75, 90, 100 ];

  window.addEventListener("scroll", throttle(() => {
    const scrollPercentage = Math.floor((window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100);
    milestones.forEach((value, index) => {
      if (scrollPercentage >= value) {
        window.dataLayer.push({
          event: "start page scroll",
          eventParams: {
            category: "start page",
            scrollDepth: `${value}`,
            scrollMeasure: "percent",
          },
        });
        milestones = milestones.slice(index + 1, milestones.length);
      }
    });
  }, 100));
}

function trackVirtualPageView(trackingData) {
  window.dataLayer.push({
    ...window.gnabb.trackingData,
    event: "page view",
    ...trackingData,
  });
}

function initLinkClickTracking() {
  document.addEventListener("click", ({ target }) => {
    const apiTarget = target.matches("a[href]") && target.closest(trackingTypeSelector("link-click"));

    if (!apiTarget) {
      return;
    }
    const destination = apiTarget.dataset.referenceDestination;
    let url = apiTarget.dataset.referenceUrl || target.href;

    if (destination === "internal" && !url.startsWith("http")) {
      url = new URL(`${window.location.origin}${url}`).toString();
    }

    trackLinkClick({
      url,
      title: apiTarget.dataset.referenceTitle || target.textContent.trim(),
      category: apiTarget.dataset.referenceType,
      destinationType: destination,
    });
  });
}

function trackLinkClick({ url, title, category = "article", destinationType = "external" }) {
  window.dataLayer.push({
    ...window.gnabb.trackingData,
    content: undefined,
    event: "link click",
    eventParams: {
      ...window.gnabb.trackingData.eventParams,
      category,
    },
    engagement: {
      type: "link click",
      component: {
        name: "feed",
        position: "main column",
        type: "teaser",
      },
      destination: {
        url,
        title,
        type: destinationType,
      },
    },
  });
}
